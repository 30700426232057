import {
  Avatar,
  Card,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import EventIcon from '@mui/icons-material/Event'

const NoneFound = () => {
  const content = useEpiContent<Content>()
  return (
    <Card
      data-testid="noneFound"
      sx={{
        container: 'bookingNoneFound',
      }}
    >
      <CardContent>
        <Typography variant="h6" component={'h2'} gutterBottom>
          {content.heading}
        </Typography>
        <List>
          <ListItem divider={false}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'secondary.light' }}>
                <EventIcon color="secondary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={content.noneFoundIngress} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button
          data-testid="findActivitiesLink"
          href={content.noneFoundLinkUrl}
          size="small"
          variant="text"
        >
          {content.noneFoundLinkText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default NoneFound
