const deniedAccessRoles = [
  'KundAvtalAvslutat',
  'TIAAktiv',
  'TIAAvslutad',
  'TIAOprovad',
  'TIAKandidat',
]

const hasDeniedAccessRoles = (roles: string[]) =>
  roles.some((role) => deniedAccessRoles.includes(role))

export default hasDeniedAccessRoles
